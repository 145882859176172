import React, { useState } from 'react';
import MediaPlanConfig from './media_plan_config';
import MediaPlanAssets from './media_plan_assets';
import MediaPlanTargets from './media_plan_targets';
import {
  CtaModal,
  PixelModal,
  DefaultTargetModal,
  ObjectiveModal,
  OptimizationModal,
  RedirectionModal,
  TargetModal,
  ZoneModal
} from './edit';
import {
  prepareMediaPlanAssets,
  prepareMediaPlanConfig,
  prepareTargets
} from './services';
import { TDefaultTargetModal } from './edit/media_plan_targets/default_target_modal/types';
import { TMediaPlanBody, TEditModal } from './types';

const MediaPlanBody: React.FC<TMediaPlanBody> = ({ mediaPlanDetails }) => {
  const [modal, setModal] = useState<TEditModal>({
    target: '',
    isModalOpen: false
  });

  const onClickEditButton = (currentTarget: string) => {
    setModal({
      isModalOpen: true,
      target: currentTarget
    });
  };

  const onCloseModal = () => {
    setModal({
      isModalOpen: false,
      target: ''
    });
  };
  return (
    <>
      <div className="media-plan-details-container-left">
        <MediaPlanConfig
          configValues={prepareMediaPlanConfig(mediaPlanDetails)}
          onClickEditButton={onClickEditButton}
        />
        <OptimizationModal
          isOpen={modal.target === 'cost'}
          onCancelAction={onCloseModal}
        />
        <ObjectiveModal
          isOpen={modal.target === 'objective'}
          onCancelAction={onCloseModal}
        />
        <PixelModal
          isOpen={modal.target === 'pixel'}
          onCancelAction={onCloseModal}
        />
        <div className="mpd-assets">
          <MediaPlanAssets
            assets={prepareMediaPlanAssets(mediaPlanDetails)}
            onClickEditButton={onClickEditButton}
          />
          <CtaModal
            isOpen={modal.target === 'cta'}
            onCancelAction={onCloseModal}
          />
          <RedirectionModal
            isOpen={modal.target === 'redirections'}
            onCancelAction={onCloseModal}
          />
        </div>
      </div>
      <div className="media-plan-details-container-right">
        <MediaPlanTargets
          targetsValues={prepareTargets(mediaPlanDetails)}
          onClickEditButton={onClickEditButton}
        />
        <DefaultTargetModal
          targetId={modal.target as TDefaultTargetModal['targetId']}
          isOpen={modal.target === 'ages' || modal.target === 'genders'}
          onCancelAction={onCloseModal}
        />
        <ZoneModal
          isOpen={modal.target === 'zones'}
          onCancelAction={onCloseModal}
        />
        <TargetModal
          isOpen={modal.target === 'targets'}
          onCancelAction={onCloseModal}
        />
      </div>
    </>
  );
};

export default MediaPlanBody;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreativeModal from '../../creative_modal';
import { VrrButton } from '../../../../shared_components';
import { TCreativeFilterActions } from './types';
import { TCreativeVideoFolder } from '../../creative_modal/types';
import OtherActions from './other_actions';
import ImportVideo from '../../import_video';
import './CreativeFilterActions.scss';

const CreativeFilterActions: React.FC<TCreativeFilterActions> = ({
  enableNewFolder,
  enableAddTag,
  enableArchive,
  enableLaunch,
  onAddTagAction,
  onArchiveAction,
  onNewFolderAction,
  onLaunchAction
}) => {
  const { t } = useTranslation();
  const [isOpenNewFolder, setIsOpenNewFolder] = useState(false);

  const onNewFolder = (newFolder: TCreativeVideoFolder) => {
    if (onNewFolderAction) onNewFolderAction(newFolder);
  };

  const onAddTag = () => {
    if (onAddTagAction) onAddTagAction();
  };

  const onArchive = () => {
    if (onArchiveAction) onArchiveAction();
  };

  return (
    <div className="creative-filter-actions">
      <h5>
        {t('creative_gallery.creative_filter.actions.title').toUpperCase()}
      </h5>
      <div className="action-buttons">
        <ImportVideo />
        <VrrButton
          dataCy="creative-gallery-new-folder-button"
          className="actions_new_folder"
          disabled={!enableNewFolder}
          onClick={() => setIsOpenNewFolder(true)}
        >
          {t('creative_gallery.creative_filter.actions.new_folder')}
        </VrrButton>
        <VrrButton
          dataCy="add-tag-button"
          className="actions_add_tag"
          disabled={!enableAddTag}
          onClick={onAddTag}
        >
          {t('creative_gallery.creative_filter.actions.add_tag')}
        </VrrButton>
        <OtherActions
          enableArchive={enableArchive}
          enableLaunch={enableLaunch}
          onArchiveAction={onArchive}
          onLaunchAction={onLaunchAction}
        />
      </div>
      <CreativeModal
        isOpen={isOpenNewFolder}
        onCancelAction={() => setIsOpenNewFolder(false)}
        onSubmitAction={onNewFolder}
        dataCy="new-folder-form"
        dataCyCancel="new-folder-cancel"
      />
    </div>
  );
};

export default CreativeFilterActions;
